import * as Sentry from '@sentry/browser';

Sentry.init({
  environment: process.env.SENTRY_ENVIRONMENT,
  dsn: 'https://f55bad66063e28702697010b20d5ea93@o510915.ingest.sentry.io/4506740514357248',
  release: process.env.SENTRY_RELEASE,
  sampleRate: parseFloat(process.env.SENTRY_SAMPLE_RATE),
  tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE),
  tracePropagationTargets: ['www.treatmyuti.local', 'treatmyuti.com'],
  integrations: [Sentry.browserTracingIntegration(), Sentry.captureConsoleIntegration({ levels: ['error'] })],
  beforeSend(event) {
    event.tags = {
      ...event.tags,
      order_id: localStorage.getItem('transaction_id') ?? null,
    };

    return event;
  },
  ignoreErrors: ['BraintreeError: Cannot contact the gateway at this time.'],
});
